import React from 'react';
//import myConfig from '../../config/myconfig';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { GalleryItems, GalleryItemsProps } from '../../config/gallery';
import GalleryAlbum from '../real-components/GalleryAlbum';


// https://stackoverflow.com/a/175787
function isNumeric(str) {
    if (typeof str !== "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseInt(str)) // ...and ensure strings of whitespace fail
}


function Gallery(props) {
    let galleryItems = GalleryItems;
    let galleryItemsProps = GalleryItemsProps;

    let galleryAlbums = [];
    let gallery_title = 'Fotogalerie';


    if(typeof props.match.params.galleryPage !== "undefined" && !isNumeric(props.match.params.galleryPage)) {
        props.history.push('/fotogalerie');
    }

    // https://codesandbox.io/s/bn7nw?file=/demo.js
    const page = Number(parseInt(props.match.params.galleryPage || '1', 10));

    if(page < 1 || page > galleryItemsProps.pages) {
        props.history.push('/fotogalerie');
    }

    if(page !== 1) {
        gallery_title += ' - Strana ' + page.toString();
    }


    let loopStart = (Number(page * galleryItemsProps.itemsPerPage) <= galleryItemsProps.items) ? Number(page * galleryItemsProps.itemsPerPage) : galleryItemsProps.items;
    let loopEnd = Number(page * galleryItemsProps.itemsPerPage - galleryItemsProps.itemsPerPage);

    for(let i = loopStart; i > loopEnd; i--) {
        galleryAlbums.push(<GalleryAlbum item={galleryItems[i]} key={galleryItems[i].key} />);
    }

    galleryAlbums.reverse();


    return (
        <React.Fragment>
            <Helmet>
                <title>{gallery_title}</title>
                <meta name="description" content="Máme zasebou již několik kompletních rekonstrukcí, staveb, montáží kuchyňských koutů, oprav a rekonstrukcí bytových jader a jiných prací. Na této stránce naleznete prezentaci našich prací." />
            </Helmet>
            <section>
                <div className={'title'}>
                    <h1 className={'hidden'}>Fotogalerie</h1>
                </div>
                <div className={'gallery'}>
                    {galleryAlbums.map((galleryAlbum) => (galleryAlbum))}
                </div>
                <div className={'gallery-pagination'}>
                    <Pagination
                        page={page}
                        count={galleryItemsProps.pages}
                        renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                to={`/fotogalerie${item.page === 1 ? '' : `/${item.page}`}`}
                                {...item}
                            />
                        )}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

export default Gallery;