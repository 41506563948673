let myConfig = {
    public_url: process.env.PUBLIC_URL,
    api_contact_url: process.env.PUBLIC_URL + '/api/contact/index.php',
    lightbox: {
        autoplaySpeed: 2500,
        slideTransitionSpeed: 0,
        transitionSpeed: 0,
        enablePanzoom: false,
    }
};

export default myConfig;