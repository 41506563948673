import React from 'react';
import myConfig from '../../config/myconfig';
import { Helmet } from 'react-helmet';

/*import PropTypes from 'prop-types';*/
import axios from 'axios';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mailSent: false,
            error: null,

            mail_name: '',
            mail_contact: '',
            mail_message: '',

            mail_name_dis: false,
            mail_contact_dis: false,
            mail_message_dis: false,
            mail_submit_dis: false
        };

        this.mail_name = React.createRef();
        this.mail_contact = React.createRef();
        this.mail_message = React.createRef();
    }

    handleFormSubmit = e => {
        e.preventDefault();

        this.setState({
            mail_name_dis: true,
            mail_contact_dis: true,
            mail_message_dis: true,
            mail_submit_dis: true
        });

        let form_data = new FormData();
        form_data.set('mail_name', this.state.mail_name);
        form_data.set('mail_contact', this.state.mail_contact);
        form_data.set('mail_message', this.state.mail_message);

        axios({
            method: 'post',
            url: myConfig.api_contact_url,
            headers: { 'content-type': 'multipart/form-data' },
            data: form_data
        }).then(result => {
            if (result.data.sent) {
                this.setState({
                    mailSent: result.data.sent,
                    error: false,

                    mail_name_dis: true,
                    mail_contact_dis: true,
                    mail_message_dis: true,
                    mail_submit_dis: true,
                });
            } else {
                this.setState({
                    mailSent: null,
                    error: true
                });
            }

            if(!this.state.mailSent) {
                this.setState({
                    mail_name_dis: false,
                    mail_contact_dis: false,
                    mail_message_dis: false,
                    mail_submit_dis: false
                });
            }
        }).catch(
            error => (
                this.setState({
                    mailSent: null,
                    error: error.message,

                    mail_name_dis: false,
                    mail_contact_dis: false,
                    mail_message_dis: false,
                    mail_submit_dis: false
                })
            )
        );
    };

    handleChange = (e, field) => {
        let value = e.target.value;

        let updateValue = {};
        updateValue[field] = value;

        this.setState(updateValue);
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Kontakt</title>
                    <meta name="description" content="Kontaktovat nás můžete na telefonním čísle +420 723 950 492 (David Born) nebo na +420 733 691 821 (Michal Born) či jakoukoliv jinou cestou zde na stránce." />
                </Helmet>
                <section>
                    <div className={'title'}>
                        <h1 className={'hidden'}>Kontakt</h1>
                    </div>
                    <article>
                        <div>
                            <p>Chcete se nás na cokoliv zeptat? Neváhejte a kontaktujte nás jakýmkoliv komunikačním kanálem uvedeným níže.</p>
                            <p>Na všechny zprávy, dotazy a jiné záležitosti se pokusíme reagovat co nejdříve.</p>
                        </div>
                        <div className={'info'}>
                            <div className={'address'}>
                                <b>David Born</b><br/>
                                Sokolská 723, 417 05 Osek<br/>
                                <b>IČO:</b> 08015309<br/>
                                <b>Tel.:</b> <a href={'tel:+420723950492'} title={'+420 723 950 492'} target={'_blank'}>+420 723 950 492</a><br/><br/>
                                <b>Michal Born</b><br/>
                                Duchcovská 1079, 415 01 Teplice<br/>
                                <b>IČO:</b> 01116975<br />
                                <b>Tel.:</b> <a href={'tel:+420733691821'} title={'+420 733 691 821'} target={'_blank'}>+420 733 691 821</a>
                            </div>
                            <div className="social">
                                <div className={'item'}>
                                    <img src={myConfig.public_url + '/assets/social/whatsapp.svg'} width={26} height={26}
                                         alt={'WhatsApp'} title={'WhatsApp'}/>
                                    <a href={'tel:+420723950492'} title={'+420 723 950 492'}
                                       target={'_blank'}>+420 723 950 492</a>
                                </div>
                                <div className={'item'}>
                                    <img src={myConfig.public_url + '/assets/social/email.svg'} width={26} height={26}
                                         alt={'Email'} title={'Email'}/>
                                    <a href={'mailto:info@rekonstrukce-born.cz?subject=Dotaz ohledně nabízených služeb&body=Dobrý den, píši Vám z Vašich webových stránek ohledně ..'}
                                       title={'info@rekonstrukce-born.cz'}
                                       target={'_blank'}>info@rekonstrukce-born.cz</a>
                                </div>
                                <div className={'item'}>
                                    <img src={myConfig.public_url + '/assets/social/instagram.svg'} width={26} height={26}
                                         alt={'Instagram'} title={'Instagram'}/>
                                    <a href={'https://instagram.com/reko_born'}
                                       title={'https://instagram.com/reko_born'}
                                       target={'_blank'}>@reko_born</a>
                                </div>
                                <div className={'item'}>
                                    <img src={myConfig.public_url + '/assets/social/facebook.svg'} width={26} height={26}
                                         alt={'Facebook'} title={'Facebook'}/>
                                    <a href={'https://fb.me/rekonstrukceborn'} title={'fb.me/rekonstrukceborn'}
                                       target={'_blank'}>fb.me/rekonstrukceborn</a>
                                </div>
                            </div>
                        </div>
                        <h2 className={'title-line'}>Napište nám</h2>
                        <div className={'form'}>
                            <form action="#">
                                <div className={'items'}>
                                    <div className={'item'}>
                                        <label htmlFor={'mail_name'}>Jméno</label>
                                        <input name={'mail_name'} minLength={2} maxLength={32} id={'mail_name'}
                                               type={'text'} placeholder={'Vaše jméno'} required
                                               onChange={e => this.handleChange(e, 'mail_name')}
                                               disabled={this.state.mail_name_dis} />
                                    </div>
                                    <div className={'item'}>
                                        <label htmlFor={'mail_contact'}>Kontakt</label>
                                        <input ref={this.mail_contact} minLength={2} maxLength={64} id={'mail_contact'}
                                               type={'text'} placeholder={'Email / telefon'} required
                                               onChange={e => this.handleChange(e, 'mail_contact')}
                                               disabled={this.state.mail_contact_dis} />
                                    </div>
                                </div>

                                <label htmlFor={'mail_message'}>Vaše zpráva</label>
                                <textarea ref={this.mail_message} name={'mail_message'} maxLength={2048} minLength={8} id={'mail_message'} rows={8}
                                          placeholder={'Zpráva, dotaz či jakékoliv jiné sdělení'} required
                                          onChange={e => this.handleChange(e, 'mail_message')}
                                          disabled={this.state.mail_message_dis}> </textarea>
                                <button type={'submit'} onClick={e => this.handleFormSubmit(e)} name={'submit'}
                                        disabled={this.state.mail_submit_dis}>Odeslat</button>
                            </form>
                        </div>
                        <div>
                            {this.state.mailSent && <span>Email byl úspěšně odeslán. Ozveme se Vám co nejdříve!</span>}
                            {this.state.error && <span>Email nemohl být odeslán, zkuste nás prosím kontaktovat jinou cestou. Omlouváme se za komplikace.</span>}
                        </div>
                    </article>
                </section>
            </React.Fragment>
        );
    }
}

export default Contact;