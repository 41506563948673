import React from 'react';
import myConfig from '../../config/myconfig.js';
/*import { Link } from 'react-router-dom';*/

function Footer() {
    return (
        <footer>
            <div className={'social'}>
                <div className={'item'}>
                    <img src={myConfig.public_url + '/assets/social/whatsapp.svg'} width={26} height={26}
                         alt={'WhatsApp'} title={'WhatsApp'} />
                    <a href={'tel:+420723950492'} title={'+420 723 950 492'}
                       target={'_blank'}>+420 723 950 492</a>
                </div>
                <div className={'item'}>
                    <img src={myConfig.public_url + '/assets/social/email.svg'} width={26} height={26}
                         alt={'Email'} title={'Email'} />
                    <a href={'mailto:info@rekonstrukce-born.cz?subject=Dotaz ohledně nabízených služeb&body=Dobrý den, píši Vám z Vašich webových stránek ohledně ..'}
                       title={'info@rekonstrukce-born.cz'}
                       target={'_blank'}>info@rekonstrukce-born.cz</a>
                </div>
                <div className={'item'}>
                    <img src={myConfig.public_url + '/assets/social/instagram.svg'} width={26} height={26}
                         alt={'Instagram'} title={'Instagram'} />
                    <a href={'https://instagram.com/reko_born'} title={'https://instagram.com/reko_born'}
                       target={'_blank'}>@reko_born</a>
                </div>
                <div className={'item'}>
                    <img src={myConfig.public_url + '/assets/social/facebook.svg'} width={26} height={26}
                         alt={'Facebook'} title={'Facebook'} />
                    <a href={'https://fb.me/rekonstrukceborn'} title={'fb.me/rekonstrukceborn'}
                       target={'_blank'}>@rekonstrukceborn</a>
                </div>
            </div>
            <div className={'details'}>
                <div className={'copy'}>
                    <span>&copy; 2023 rekonstrukce-born.cz</span>
                </div>
                <div>
                    <div className={'legal'}>
                        <span>David Born</span>
                        <span>IČO: 08015309</span>
                        <span>Sokolská 723, 417 05 Osek</span>
                    </div>
                    <div className={'legal'}>
                        <span>Michal Born</span>
                        <span>IČO: 01116975</span>
                        <span>Duchcovská 1079, 415 01 Teplice</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;