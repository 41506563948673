import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
//import myConfig from '../config/myconfig.js';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '../App.css';

import Header from './parts/Header.js';
import Footer from './parts/Footer.js';

import Home from './pages/Home.js';
import Gallery from './pages/Gallery.js';
import Contact from './pages/Contact.js';
import Aktuality from "./pages/Actuales";

function App() {
    return (
        <Router>
                <div className={'App'}>
                    <Helmet titleTemplate={'Rekonstrukce BORN - %s'} defaultTitle={'Rekonstrukce BORN'}>
                    </Helmet>
                    <Header />
                    <Switch>
                        <Route path={'/'} exact={true} component={Home} />
                        <Route path={'/fotogalerie/:galleryPage?'} component={Gallery} />
                        <Route path={'/kontakt'} component={Contact} />
                        <Route path={'/aktuality'} component={Aktuality} />
                    </Switch>
                    <Footer />
                </div>
        </Router>
    );
}

export default App;
