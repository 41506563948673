import React from 'react';
import myConfig from '../../config/myconfig.js';
import { Helmet } from 'react-helmet';

function Actuales() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Aktuality</title>
                <meta name="description" content="Provádíme rekonstrukce, opravy bytových jader, sádrokartonové práce, zatepolování, montáže oken i dveří a další. Naše firma působí v Ústeckém kraji, primárně v Teplicích (Teplice v Čechách) a na Teplicku." />
            </Helmet>
            <section>
                <div className={'title'}>
                    <h1 className={'hidden'}>Aktuality</h1>
                </div>
                <article>
                    <div>
                        <p className={'center'}>To nejaktuálnější pro Vás máme vždy na našich sociálních sítích.<br/>
                            Níže pro Vás máme připravené naše příspěvky z Facebooku.</p>

                        <ul className={'list'}>
                            <li>
                                <img src={myConfig.public_url + '/assets/social/facebook.svg'} width={26} height={26}
                                     alt={'Facebook'} title={'Facebook'} />
                                <div>
                                    <span>Facebook</span>
                                    <a href={'https://fb.me/rekonstrukceborn'} title={'fb.me/rekonstrukceborn'}
                                       target={'_blank'}>@rekonstrukceborn</a>
                                </div>
                            </li>
                            <li>
                                <img src={myConfig.public_url + '/assets/social/instagram.svg'} width={26} height={26}
                                     alt={'Instagram'} title={'Instagram'} />
                                <div>
                                    <span>Instagram</span>
                                    <a href={'https://instagram.com/reko_born'} title={'https://instagram.com/reko_born'}
                                       target={'_blank'}>@reko_born</a>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div style={{display: 'flex', 'justify-content': 'center'}}>
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frekonstrukceborn&tabs=timeline&width=350&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=631615303860140"
                                width={350} height={500} style={{border:'none', overflow:'hidden'}} scrolling={"no"} frameBorder={0} title={"Facebooková stránka"}></iframe>
                    </div>
                </article>
            </section>
        </React.Fragment>
    );
}

export default Actuales;