import React from 'react';
import myConfig from '../../config/myconfig.js';
import { Helmet } from 'react-helmet';

function Home() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Domů</title>
                <meta name="description" content="Provádíme rekonstrukce, opravy bytových jader, sádrokartonové práce, zatepolování, montáže oken i dveří a další. Naše firma působí v Ústeckém kraji, primárně v Teplicích (Teplice v Čechách) a na Teplicku." />
            </Helmet>
            <section>
                <div className={'title'}>
                    <h1>Provádíme kompletní rekonstrukce na klíč a bězné opravy v domácnostech.</h1>
                </div>
                <article>
                    <div>
                        <p>Provádíme <b>kompletní rekonstrukce na klíč</b> ale i <b>bězné opravy v domácnostech</b>.
                            Zabýváme se
                            službami <b>hodinového manžela</b>, nabízíme <b>vyklízení nemovitostí</b> a
                            i <b>stěhování</b>.
                            Jsme rodinná firma která dbá na kvalitu za rozumnou cenu.</p>
                        <p>Naše firma působí v <b>Ústeckém kraji</b>, primárně v <b>Teplicích</b> (Teplice v Čechách) a
                            na Teplicku. Často pracujeme
                            s klienty z blízkého okolí Teplic - z měst Litvínov, Osek, Duchcov, Dubí, Litoměříce, Ústí
                            nad Labem, Most aj.</p>
                        <p>Provádíme <b>sádrokartonové práce</b>, <b>vyzdívání</b>, <b>zatepolování</b>, <b>opravy všech
                            druhů omítek a stěn</b>, <b>realizujeme
                            montáže oken i dveří</b> a <b>kompletní rekonstrukce</b> jak <b>domů</b>, <b>bytů</b>, <b>objektů
                            všech typů</b>.</p>
                        <p>Každou poptávku probíráme s klienty <b>individuálně</b> a snažíme se klientům nabídnout
                            tu <b>nejkvalitnější
                                možnou cestu realizace</b> dle jejich rozpočtu.</p>
                    </div>

                    <h2 className={'title-line'}>Rekonstrukce</h2>
                    <div className={'service'}>
                        <div className={'image'}>
                            <img src={myConfig.public_url + '/assets/services/rekonstrukce.svg'} alt={'Rekonstrukce'}
                                 title={'Rekonstrukce'}/>
                        </div>
                        <div className={'content'}>
                            <p>Provádíme kompletní rekonstrukce na klíč, ať už bytových jednotek nebo celých domů či
                                jiných objektů.</p>
                            <p>Mezi našimi referencemi naleznete kompletní rekonstrukce bytových jader, kanceláří a
                                přestavby
                                částí rodinných domů.</p>
                            <p>V rámci jakékoliv rekonstrukce vše s klienty řešíme individuálně včetně materiálu který
                                bude
                                použit na danou práci.</p>
                        </div>
                    </div>

                    <h2 className={'title-line'}>Kuchyně</h2>
                    <div className={'service'}>
                        <div className={'image'}>
                            <img src={myConfig.public_url + '/assets/services/kuchyne.svg'} alt={'Kuchyně'}
                                 title={'Kuchyně'}/>
                        </div>
                        <div className={'content'}>
                            <p>Jsme schopni provést instalici nových či zánovních kuchyňských koutů na míru, výměnu
                                kuchyňských
                                spotřebičů nebo např. kuchyňského dřezu, digestoře apd.</p>
                            <p>Spolupracujeme s firmami IKEA, SIKO a Kuchyně Macháček Teplice. S uvedenými firmami máme
                                bohaté zkušenosti a jsme schopni pro Vás zařídit kompletní instalaci od A do Z.</p>
                        </div>
                    </div>

                    <h2 className={'title-line'}>Hodinový manžel</h2>
                    <div className={'service'}>
                        <div className={'image'}>
                            <img src={myConfig.public_url + '/assets/services/manzel.svg'} alt={'Hodinový manžel'}
                                 title={'Hodinový manžel'}/>
                        </div>
                        <div className={'content'}>
                            <p>Potřebujete seskládat nový nábytek jakékoliv značky, rozmontovat starý nábytek nebo
                                pomoct s
                                instalací spotřebičů všech velikostí, s jejich přepravou, vrtáním do panelů, instalací
                                nových zásuvek či s čímkoliv co Vás napadne?</p>
                            <p>Pro nás to není žádný problém a po probrání jakákoliv žádosti jsme Vám schopni vyjít
                                vstříc
                                s jakýmkoliv problémem ve Vaší domácnosti!</p>
                        </div>
                    </div>

                    <h2 className={'title-line'}>Elektrikářské a vodárenské práce</h2>
                    <div className={'service'}>
                        <div className={'image'}>
                            <img src={myConfig.public_url + '/assets/services/el_vod.svg'} alt={'Elektrikářské a vodárenské práce'}
                                 title={'Elektrikářské a vodárenské práce'}/>
                        </div>
                        <div className={'content'}>
                            <p>S elektrikářskými a vodárenskými pracemi se setkáváme skoro při každé rekonstrukci a
                                proto
                                nabízíme tutu službu i samostatně, pokud by jste potřebovali třeba při Vaší vlastní
                                rekonstrukci s něčím pomoct.</p>
                            <p>Zvládneme vyměnit starší bytový jistič, opravit rozbitý odpad, vyměnit
                                kuchyňský dřez či vyměnit starší bytové zásuvky.</p>
                        </div>
                    </div>

                    <h2 className={'title-line'}>Stěhování, vyklízení a přeprava zboží</h2>
                    <div className={'service'}>
                        <div className={'image'}>
                            <img src={myConfig.public_url + '/assets/services/doprava.svg'} alt={'Stěhování, vyklízení a přeprava zboží'}
                                 title={'Stěhování, vyklízení a přeprava zboží'}/>
                        </div>
                        <div className={'content'}>
                            <p>Po rekonstrukci a různých dokončovacích pracích Vám rádi pomůžeme s nastěhováním
                                do Vašeho nového domova, a nebo Vám rádi i dovezeme velký nabýtek, elektrospotřebiče
                                a zajistíme i jeho vynesení a umístění tam, kam budete potřebovat.</p>
                        </div>
                    </div>

                    <h2 className={'title-line'}>Drobný autoservis</h2>
                    <div className={'service'}>
                        <div className={'image'}>
                            <img src={myConfig.public_url + '/assets/services/servis.svg'} alt={'Drobný autoservis'}
                                 title={'Drobný autoservis'}/>
                        </div>
                        <div className={'content'}>
                            <p>Nechtějí Vám v běžném autoservisu vyjít vstříc s malými servisními úkony, potřebujete
                                jenom vyměnit olej včetně filtrů, vyměnit brzdy a kotouče, nebo provést jakýkoliv jiný
                                servisní úkon?</p>
                            <p>Neváhejte nás oslovit, zákazníkům vyjdeme vstříc i o víkendových termínech.</p>
                        </div>
                    </div>

                    <h2 className={'line-title-line'}>Jak postupujeme?</h2>
                    <div className={'progress'}>
                        <span>Kontaktujete nás</span>
                        <span className={'progress-arrow'}><img
                            src={myConfig.public_url + '/assets/angle-double-down-solid.svg'} width={'16'} height={'16'}
                            alt={'Postup'} title={'Postup'}/></span>
                        <span>Prodiskutujeme Vaši<br/>žádost</span>
                        <span className={'progress-arrow'}><img
                            src={myConfig.public_url + '/assets/angle-double-down-solid.svg'} width={'16'} height={'16'}
                            alt={'Postup'} title={'Postup'}/></span>
                        <span>Navrhneme individuálně<br/>realizaci a postup</span>
                        <span className={'progress-arrow'}><img
                            src={myConfig.public_url + '/assets/angle-double-down-solid.svg'} width={'16'} height={'16'}
                            alt={'Postup'} title={'Postup'}/></span>
                        <span>Předáme hotovou<br/>práci</span>
                    </div>
                </article>
            </section>
        </React.Fragment>
    );
}

export default Home;