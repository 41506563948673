import React from 'react';
import myConfig from '../../config/myconfig.js';
import { NavLink, Link } from 'react-router-dom';

function Header() {
    return (
        <header>
            <div className={'logo'}>
                <Link to={'/'} title={'Rekonstrukce BORN'}>
                    <img src={myConfig.public_url + '/assets/logo/logo.svg'} width={100} height={79} alt={'BORN'} title={'Rekonstrukce BORN'} />
                </Link>
            </div>
            <nav className={'main'} role={'main'}>
                <ul>
                    <li>
                        <NavLink exact={true} activeClassName={'active'} to={'/'} title={'Domů'}>Domů</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={'active'} to={'/aktuality'} title={'Aktuality'}>Aktuality</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={'active'} to={'/fotogalerie'} title={'Fotogalerie'}>Fotogalerie</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={'active'} to={'/kontakt'} title={'Kontakt'}>Kontakt</NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;