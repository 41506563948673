/*
    in title theres 'preview' and 'details' hidden
 */

/*
    {
        key: 26,
        core_url: 'asdasd/asdasd/',
        preview: 7,
        title: {
            details: {
                place: 'Mesto',
                work: 'Neconeconeco'
            },
            date: 'mesic 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 100,
            exclude: [ ]
        }
    },
 */

let galleryItems = [
    {
        key: 83,
        core_url: 'teplice/rekonstrukce_bytu_stukovani/',
        preview: 4,
        title: {
            details: {
                place: 'Teplice',
                work: 'rekonstrukce bytu, štukování'
            },
            date: 'březen 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 4 ]
        }
    },
    {
        key: 82,
        core_url: 'hradec_kralove/priprava_na_stavbu_plotu/',
        preview: 2,
        title: {
            details: {
                place: 'Hradec Králové',
                work: 'příprava na stavbu plotu'
            },
            date: 'březen 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 4,
            exclude: [ 2 ]
        }
    },
    {
        key: 81,
        core_url: 'praha_smichov/priprava_na_prepravni_plosinu/',
        preview: 5,
        title: {
            details: {
                place: 'Praha, Smíchov',
                work: 'příprava na přepravní plošinu'
            },
            date: 'březen 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 5,
            exclude: [ 5 ]
        }
    },
    {
        key: 80,
        core_url: 'teplice/rekonstrukce_bytu_vyrovnani_podlah/',
        preview: 5,
        title: {
            details: {
                place: 'Teplice',
                work: 'rekonstrukce bytu, vyrovnání podlah'
            },
            date: 'březen 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ 5 ]
        }
    },
    {
        key: 79,
        core_url: 'osek/renovace_obyvaciho_pokoje/',
        preview: 7,
        title: {
            details: {
                place: 'Osek',
                work: 'renovace obyvacího pokoje'
            },
            date: 'únor 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 7 ]
        }
    },
    {
        key: 78,
        core_url: 'louny/vatovani_stropu/',
        preview: 1,
        title: {
            details: {
                place: 'Louny',
                work: 'vatování stropu'
            },
            date: 'leden 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 3,
            exclude: [ 1 ]
        }
    },
    {
        key: 77,
        core_url: 'mrzlice/vytvoreni_podlahy_druhe_patro/',
        preview: 8,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'vytvoření nové podlahy ve druhém patře'
            },
            date: 'leden 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ 8 ]
        }
    },
    {
        key: 76,
        core_url: 'sobeslav/oprava_poskozene_kanalizace/',
        preview: 7,
        title: {
            details: {
                place: 'Soběslav',
                work: 'oprava poškozené kanalizace'
            },
            date: 'leden 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 10,
            exclude: [ 7 ]
        }
    },
    {
        key: 75,
        core_url: 'praha_modrany/instalace_zabradli_pokracovani/',
        preview: 9,
        title: {
            details: {
                place: 'Praha, Modřany',
                work: 'instalace zábradlí, pokračování'
            },
            date: 'leden 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 15,
            exclude: [ 9 ]
        }
    },
    {
        key: 74,
        core_url: 'louny/priprava_navareni_izolace/',
        preview: 9,
        title: {
            details: {
                place: 'Louny',
                work: 'příprava a navaření izolace'
            },
            date: 'leden 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 13,
            exclude: [ 9 ]
        }
    },
    {
        key: 73,
        core_url: 'louny/priprava_rastru_sdk/',
        preview: 5,
        title: {
            details: {
                place: 'Louny',
                work: 'příprava rastrů pro SDK'
            },
            date: 'leden 2024',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 5 ]
        }
    },
    {
        key: 72,
        core_url: 'louny/montaz_vyloh_manipulator/',
        preview: 2,
        title: {
            details: {
                place: 'Louny',
                work: 'montáž výloh s manipulátorem'
            },
            date: 'prosinec 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ 2 ]
        }
    },
    {
        key: 71,
        core_url: 'osek/rekonstrukce_bytu/',
        preview: 7,
        title: {
            details: {
                place: 'Osek',
                work: 'rekonstrukce bytu'
            },
            date: 'prosinec 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 7 ]
        }
    },
    {
        key: 70,
        core_url: 'mrzlice/instalace_podlahoveho_topeni/',
        preview: 4,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'instalace podlahového topení'
            },
            date: 'prosinec 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ 4 ]
        }
    },
    {
        key: 69,
        core_url: 'praha_modrany/instalace_zabradli/',
        preview: 1,
        title: {
            details: {
                place: 'Praha, Modřany',
                work: 'instalace zábradlí'
            },
            date: 'prosinec 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 1 ]
        }
    },
    {
        key: 68,
        core_url: 'hodonin/dokoncovaci_prace_vytahova_sachta/',
        preview: 5,
        title: {
            details: {
                place: 'Hodonín',
                work: 'Dokončovací práce na výtahové šachtě'
            },
            date: 'prosinec 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 5,
            exclude: [ 5 ]
        }
    },
    {
        key: 67,
        core_url: 'skoleni/doorhan/',
        preview: 1,
        title: {
            details: {
                place: 'Školení',
                work: 'DoorHan'
            },
            date: 'říjen 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 1,
            exclude: [ ]
        }
    },
    {
        key: 66,
        core_url: 'louny/montaz_oken/',
        preview: 11,
        title: {
            details: {
                place: 'Louny',
                work: 'montáž oken'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 13,
            exclude: [ 11 ]
        }
    },
    {
        key: 65,
        core_url: 'mrzlice/stresni_vykyr_montaz_okna_strecha/',
        preview: 12,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'střešní vykýř, montáž okna a úpravy střechy'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 12,
            exclude: [ 12 ]
        }
    },
    {
        key: 64,
        core_url: 'vlci_habrina/omitani_rozvody_elektriny/',
        preview: 6,
        title: {
            details: {
                place: 'Vlčí Habřina',
                work: 'omítání a rozvody elektřiny'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ 6 ]
        }
    },
    {
        key: 63,
        core_url: 'jindrichuv_hradec/dokoncovaci_prace_vytahova_sachta/',
        preview: 3,
        title: {
            details: {
                place: 'Jindřichův Hradec',
                work: 'dokončovací práce na výtahové šachtě'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 3,
            exclude: [ 3 ]
        }
    },
    {
        key: 62,
        core_url: 'hodonin/rekonstrukce_vytahove_sachty/',
        preview: 3,
        title: {
            details: {
                place: 'Hodonín',
                work: 'rekonstrukce výtahové šachty'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 3,
            exclude: [ 3 ]
        }
    },
    {
        key: 61,
        core_url: 'mrzlice/uprava_strechy_nad_schodistem/',
        preview: 9,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'úprava střechy nad schodištěm'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ 9 ]
        }
    },
    {
        key: 60,
        core_url: 'ivancice/oprava_soklu_panelovy_dum/',
        preview: 6,
        title: {
            details: {
                place: 'Ivančice',
                work: 'oprava soklu, panelový dům, příprava'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 6 ]
        }
    },
    {
        key: 59,
        core_url: 'dacice/rekonstrukce_kanalizace/',
        preview: 5,
        title: {
            details: {
                place: 'Dačice',
                work: 'rekonstrukce kanalizace'
            },
            date: 'září 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ 5 ]
        }
    },
    {
        key: 58,
        core_url: 'hodonin/venkovni_upravy_kolem_paneloveho_domu/',
        preview: 1,
        title: {
            details: {
                place: 'Hodonín',
                work: 'Venkovní úpravy kolem panelového domu'
            },
            date: 'červen 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 1 ]
        }
    },
    {
        key: 57,
        core_url: 'jindrichuv_hradec/uprava_dverniho_prostoru/',
        preview: 5,
        title: {
            details: {
                place: 'Jindřichův Hradec',
                work: 'Úprava dveřního prostoru'
            },
            date: 'červen 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 5,
            exclude: [ 5 ]
        }
    },

    {
        key: 56,
        core_url: 'jindrichuv_hradec/prelozeni_kanalizace/',
        preview: 6,
        title: {
            details: {
                place: 'Jindřichův Hradec',
                work: 'Přeložení kanalizace'
            },
            date: 'květen 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ 6 ]
        }
    },
    {
        key: 55,
        core_url: 'trebic/oprava_poskozene_kanalizace/',
        preview: 5,
        title: {
            details: {
                place: 'Třebíč',
                work: 'Oprava poškozené kanalizace'
            },
            date: 'květen 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ ]
        }
    },

    {
        key: 54,
        core_url: 'mrzlice/priprava_rastru_na_sdk_stresni_okna/',
        preview: 3,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'Příprava rastru na SDK a montáž střešních oken'
            },
            date: 'duben 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 3,
            exclude: [ 3 ]
        }
    },
    {
        key: 53,
        core_url: 'litomerice/montaz_rolet_a_site_stresni_okna/',
        preview: 1,
        title: {
            details: {
                place: 'Litoměřice',
                work: 'Montáž rolet a sítě pro střešní okna'
            },
            date: 'duben 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 4,
            exclude: [ 1 ]
        }
    },
    {
        key: 52,
        core_url: 'jindrichuv_hradec/priprava_pro_novou_vytahovou_sachtu/',
        preview: 1,
        title: {
            details: {
                place: 'Jindřichův Hradec',
                work: 'Příprava pro novou výtahovou šachtu'
            },
            date: 'duben 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ 1 ]
        }
    },
    {
        key: 51,
        core_url: 'jindrichuv_hradec/presun_schodistoveho_ramene/',
        preview: 11,
        title: {
            details: {
                place: 'Jindřichův Hradec',
                work: 'Přesun schodištového ramene'
            },
            date: 'duben 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 11,
            exclude: [ 11 ]
        }
    },

    {
        key: 50,
        core_url: 'litomerice/dokoncovaci_prace_v_podkrovi/',
        preview: 3,
        title: {
            details: {
                place: 'Litoměřice',
                work: 'Dokončovací práce v podkroví'
            },
            date: 'únor 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 5,
            exclude: [ 3 ]
        }
    },
    {
        key: 49,
        core_url: 'trebic/rekonstrukce_vytahu/',
        preview: 4,
        title: {
            details: {
                place: 'Třebíč',
                work: 'Rekonstrukce výtahu'
            },
            date: 'únor 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 5,
            exclude: [ 4 ]
        }
    },
    {
        key: 48,
        core_url: 'hodonin/pokladka_dlazby_panelovy_dum/',
        preview: 6,
        title: {
            details: {
                place: 'Hodonín',
                work: 'Pokládka dlažby v panelovém domě'
            },
            date: 'únor 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ 6 ]
        }
    },
    {
        key: 47,
        core_url: 'trebic/izolace_destoveho_svodu/',
        preview: 4,
        title: {
            details: {
                place: 'Třebíč',
                work: 'Izolace dešťového svodu'
            },
            date: 'leden 2023',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 4,
            exclude: [ 4 ]
        }
    },
    {
        key: 46,
        core_url: 'nemecko/prace_na_penzionu/',
        preview: 7,
        title: {
            details: {
                place: 'Am Mühlgraben Freiberg',
                work: 'Rekonstrukce penzionu'
            },
            date: 'prosinec 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 12,
            exclude: [ ]
        }
    },
    {
        key: 45,
        core_url: 'teplice/vchodove_dvere_porta/',
        preview: 2,
        title: {
            details: {
                place: 'Teplice',
                work: 'Montáž vchodových dveří Porta'
            },
            date: 'prosinec 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ ]
        }
    },
    {
        key: 44,
        core_url: 'pardubice/prace_na_krovu_zaplachtovani/',
        preview: 12,
        title: {
            details: {
                place: 'Pardubice',
                work: 'Dokončení prací na krovu a zaplachtování'
            },
            date: 'prosinec 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 13,
            exclude: [ ]
        }
    },
    {
        key: 43,
        core_url: 'mrzlice/rekonstrukce_domu_prosinec/',
        preview: 9,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'Rekonstrukce domu, podlahové topení'
            },
            date: 'prosinec 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 16,
            exclude: [ ]
        }
    },
    {
        key: 42,
        core_url: 'mrzlice/dokryti_strechy_koupelny/',
        preview: 2,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'Dokrytí střechy koupelny'
            },
            date: 'prosinec 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 3,
            exclude: [ ]
        }
    },


    {
        key: 41,
        core_url: 'firma/firemni_flotila/',
        preview: 1,
        title: {
            details: {
                place: 'Společnost',
                work: 'Přírůstek do firemní flotily'
            },
            date: 'listopad 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ ]
        }
    },
    {
        key: 40,
        core_url: 'pardubice/prace_na_rodinnem_dome/',
        preview: 6,
        title: {
            details: {
                place: 'Pardubice',
                work: 'Práce na rodinném domě'
            },
            date: 'listopad 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ 6 ]
        }
    },
    {
        key: 39,
        core_url: 'mrzlice/budouci_prostory_spolecnosti/',
        preview: 8,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'Budoucí prostory společnosti'
            },
            date: 'listopad 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ 8 ]
        }
    },
    {
        key: 38,
        core_url: 'litomerice/rekonstrukce_domu_listopad/',
        preview: 7,
        title: {
            details: {
                place: 'Litoměřice',
                work: 'Rekonstrukce domu'
            },
            date: 'listopad 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 7 ]
        }
    },
    {
        key: 37,
        core_url: 'kamenice_nad_lipou/rekonstruce_hlavniho_vestibulu/',
        preview: 5,
        title: {
            details: {
                place: 'Kamenice nad Lipou',
                work: 'Rekonstrukce hlavního vestibulu'
            },
            date: 'listopad 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ ]
        }
    },



    {
        key: 36,
        core_url: 'trebic/oprava_kanalizace_v_panelovem_dome/',
        preview: 6,
        title: {
            details: {
                place: 'Třebíč',
                work: 'Oprava kanalizace v panelovém domě'
            },
            date: 'říjen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ ]
        }
    },
    {
        key: 35,
        core_url: 'trebic/rekonstrukce_jidelniho_vytahu/',
        preview: 5,
        title: {
            details: {
                place: 'Třebíč',
                work: 'MŠ, rekonstrukce jídelního výtahu'
            },
            date: 'říjen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ ]
        }
    },
    {
        key: 34,
        core_url: 'lom/pokladka_zamkove_dlazby/',
        preview: 1,
        title: {
            details: {
                place: 'Lom',
                work: 'Pokládka zámkové dlažby'
            },
            date: 'říjen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 3,
            exclude: [ 1 ]
        }
    },
    {
        key: 33,
        core_url: 'litomerice/rekonstrukce_domu/',
        preview: 2,
        title: {
            details: {
                place: 'Litoměřice',
                work: 'Rekonstrukce domu'
            },
            date: 'říjen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 2,
            max: 16,
            exclude: [ 2 ]
        }
    },
    {
        key: 32,
        core_url: 'kamenice_nad_lipou/oprava_destove_kanalizace/',
        preview: 3,
        title: {
            details: {
                place: 'Kamenice nad Lipou',
                work: 'Oprava dešťové kanalizace'
            },
            date: 'říjen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ ]
        }
    },
    {
        key: 31,
        core_url: 'hodonin/rekonstrukce_vytahu/',
        preview: 1,
        title: {
            details: {
                place: 'Hodonín',
                work: 'Rekonstrukce výtahu'
            },
            date: 'říjen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ ]
        }
    },


    {
        key: 30,
        core_url: 'teplice/rekonstrukce_bytu/',
        preview: 16,
        title: {
            details: {
                place: 'Teplice',
                work: 'Rekonstrukce bytu'
            },
            date: 'červen 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 18,
            exclude: [ ]
        }
    },


    {
        key: 29,
        core_url: 'krupka/rekonstrukce_mistnosti_panelovy_dum/',
        preview: 1,
        title: {
            details: {
                place: 'Krupka',
                work: 'Rekonstrukce místnosti v panelovém domě'
            },
            date: 'duben 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ ]
        }
    },
    {
        key: 28,
        core_url: 'dacice/rekonstrukce_vytahu_nemocnice/',
        preview: 4,
        title: {
            details: {
                place: 'Dačice',
                work: 'Rekonstrukce výtahu nemocnice'
            },
            date: 'duben 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 9,
            exclude: [ ]
        }
    },


    {
        key: 27,
        core_url: 'teplice/rekonstrukce_koupelny/',
        preview: 1,
        title: {
            details: {
                place: 'Teplice',
                work: 'Rekostrukce koupelny'
            },
            date: 'únor 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ ]
        }
    },


    {
        key: 26,
        core_url: 'praha/opravy_v_cinzovnim_dome/',
        preview: 7,
        title: {
            details: {
                place: 'Praha',
                work: 'Opravy v činžovním domě'
            },
            date: 'leden 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 8,
            exclude: [ ]
        }
    },
    {
        key: 25,
        core_url: 'pardubice/zahajeni_praci_na_krovu/',
        preview: 2,
        title: {
            details: {
                place: 'Pardubice',
                work: 'Zahájení prací na krovu'
            },
            date: 'leden 2022',
        },
        images_source: {
            name: '',
            extension: 'jpg',
            min: 1,
            max: 6,
            exclude: [ ]
        }
    },


    {
        key: 24,
        core_url: 'osek/rekonstrukce_bytu_pokladka_vinylu/',
        preview: 14,
        title: {
            details: {
                place: 'Osek',
                work: 'Rekonstrukce bytu a pokládka vinylu'
            },
            date: 'leden 2022',
        },
        images_source: {
            name: 'rekonstrukce_bytu',
            extension: 'jpeg',
            min: 1,
            max: 15,
            exclude: [ 14 ]
        }
    },
    {
        key: 23,
        core_url: 'most/rekonstrukce_bytu/',
        preview: 1,
        title: {
            details: {
                place: 'Most',
                work: 'Rekonstrukce bytu'
            },
            date: 'leden 2022',
        },
        images_source: {
            name: 'rekonstrukce_bytu',
            extension: 'jpeg',
            min: 1,
            max: 5,
            exclude: [ 1 ]
        }
    },
    {
        key: 22,
        core_url: 'psary/demolice/',
        preview: 1,
        title: {
            details: {
                place: 'Psáry',
                work: 'Demolice'
            },
            date: 'leden 2022',
        },
        images_source: {
            name: 'demolice',
            extension: 'jpeg',
            min: 1,
            max: 5,
            exclude: [ 1 ]
        }
    },
    {
        key: 21,
        core_url: 'osek/rekonstrukce_koupelny/',
        preview: 4,
        title: {
            details: {
                place: 'Osek',
                work: 'Rekonstrukce koupelny'
            },
            date: 'prosinec 2021',
        },
        images_source: {
            name: 'rekonstrukce_koupelny',
            extension: 'jpeg',
            min: 1,
            max: 8,
            exclude: [ 4 ]
        }
    },
    {
        key: 20,
        core_url: 'most/vytahova_sachta/',
        preview: 6,
        title: {
            details: {
                place: 'Most',
                work: 'Rekonstrukce výtahové šachty, zacištění nových vstupů'
            },
            date: 'srpen 2021',
        },
        images_source: {
            name: 'vytahova_sachta',
            extension: 'jpeg',
            min: 1,
            max: 7,
            exclude: [ 6 ]
        }
    },
    {
        key: 19,
        core_url: 'most/fasada/',
        preview: 7,
        title: {
            details: {
                place: 'Most',
                work: 'Fasáda'
            },
            date: 'červenec 2021',
        },
        images_source: {
            name: 'fasada',
            extension: 'jpeg',
            min: 1,
            max: 7,
            exclude: [ 7 ]
        }
    },
    {
        key: 18,
        core_url: 'trebic/hruba_stavba/',
        preview: 1,
        title: {
            details: {
                place: 'Třebíč, Slavice',
                work: 'Hrubá stavba, zalití věnce'
            },
            date: 'červen 2021',
        },
        images_source: {
            name: 'hruba_stavba',
            extension: 'jpeg',
            min: 1,
            max: 22,
            exclude: [ 1 ]
        }
    },
    {
        key: 17,
        core_url: 'trebic/zakladova_deska/',
        preview: 10,
        title: {
            details: {
                place: 'Třebíč, Slavice',
                work: 'Základová deska'
            },
            date: 'květen 2021',
        },
        images_source: {
            name: 'zaklady',
            extension: 'jpeg',
            min: 1,
            max: 19,
            exclude: [ 10 ]
        }
    },
    {
        key: 16,
        core_url: 'most/vinylove_podlahy_nabytek/',
        preview: 12,
        title: {
            details: {
                place: 'Most',
                work: 'Pokládka vinylové podlahy, kompletace nábytku'
            },
            date: 'květen 2021',
        },
        images_source: {
            name: 'pokladka_nabytek',
            extension: 'jpeg',
            min: 1,
            max: 12,
            exclude: [ 12 ]
        }
    },
    {
        key: 15,
        core_url: 'lom/prirodni_kamen/',
        preview: 1,
        title: {
            details: {
                place: 'Lom',
                work: 'Lepení přírodního kamene'
            },
            date: 'duben 2021',
        },
        images_source: {
            name: 'prirodni_kamen',
            extension: 'jpeg',
            min: 1,
            max: 6,
            exclude: [ 1 ]
        }
    }, {
        key: 14,
        core_url: 'lom/betonovani_operna_zed/',
        preview: 9,
        title: {
            details: {
                place: 'Lom',
                work: 'Betonování základů,stavba opěrné zdi'
            },
            date: 'duben 2021',
        },
        images_source: {
            name: 'betonovani_operna_zed',
            extension: 'jpeg',
            min: 1,
            max: 14,
            exclude: [ 9 ]
        }
    }, {
        key: 13,
        core_url: 'mrzlice/sdk_a_elektroinstalace/',
        preview: 1,
        title: {
            details: {
                place: 'Mrzlice',
                work: 'Montáž SDK a elektroinstalace'
            },
            date: 'duben 2021',
        },
        images_source: {
            name: 'sdk_a_elektroinstalace',
            extension: 'jpeg',
            min: 1,
            max: 15,
            exclude: [ 1 ]
        }
    }, {
        key: 12,
        core_url: 'lom/linka_a_nabytek/',
        preview: 7,
        title: {
            details: {
                place: 'Lom',
                work: 'Kuchyňská linka a nábytek'
            },
            date: 'duben 2021',
        },
        images_source: {
            name: 'linka_a_nabytek',
            extension: 'jpeg',
            min: 1,
            max: 9,
            exclude: [ 7 ]
        }
    }, {
        key: 11,
        core_url: 'lom/koupelny_a_podlahy/',
        preview: 3,
        title: {
            details: {
                place: 'Lom',
                work: 'Koupelny a podlahy'
            },
            date: 'duben 2021',
        },
        images_source: {
            name: 'koupelny_podlahy',
            extension: 'jpeg',
            min: 1,
            max: 18,
            exclude: [ 3 ]
        }
    }, {
        key: 10,
        core_url: 'osek/realizace_koupelny/',
        preview: 2,
        title: {
            details: {
                place: 'Osek',
                work: 'Realizace koupelny'
            },
            date: 'březen 2021',
        },
        images_source: {
            name: 'realizace_koupelny',
            extension: 'jpeg',
            min: 1,
            max: 6,
            exclude: [ 2 ]
        }
    }, {
        key: 9,
        core_url: 'decin/rekonstrukce_bytu/',
        preview: 27,
        title: {
            details: {
                place: 'Děčín',
                work: 'Rekonstrukce bytu'
            },
            date: 'leden 2021',
        },
        images_source: {
            name: 'rekonstrukce_bytu',
            extension: 'jpeg',
            min: 1,
            max: 29,
            exclude: [ 27 ]
        }
    }, {
        key: 8,
        core_url: 'pardubice/novostavba/',
        preview: 26,
        title: {
            details: {
                place: 'Pardubice',
                work: 'Novostavba'
            },
            date: 'rok 2020',
        },
        images_source: {
            name: 'novostavba',
            extension: 'jpeg',
            min: 1,
            max: 26,
            exclude: [ 26 ]
        }
    }, {
        key: 7,
        core_url: 'pelhrimov/prelozka_kanalizace/',
        preview: 8,
        title: {
            details: {
                place: 'Pelhřimov',
                work: 'Přeložka kanalizace v panelovém domě'
            },
            date: 'únor 2020',
        },
        images_source: {
            name: 'prelozka_kanalizace',
            extension: 'jpeg',
            min: 1,
            max: 11,
            exclude: [ 8 ]
        }
    }, {
        key: 6,
        core_url: 'trebic/vytahova_sachta/',
        preview: 6,
        title: {
            details: {
                place: 'Třebíč',
                work: 'Zhotovení výtahové šachty'
            },
            date: 'únor 2020',
        },
        images_source: {
            name: 'vytahova_sachta',
            extension: 'jpeg',
            min: 1,
            max: 7,
            exclude: [ 6 ]
        }
    }, {
        key: 5,
        core_url: 'dubi/prestavba_koupelny/',
        preview: 1,
        title: {
            details: {
                place: 'Dubí',
                work: 'Přestavba koupelny'
            },
            date: 'rok 2019',
        },
        images_source: {
            name: 'prestavba_koupelny',
            extension: 'jpg',
            min: 1,
            max: 5,
            exclude: [ 1 ]
        }
    }, {
        key: 4,
        core_url: 'hostomice/prestavba_koupelny_kuchyne/',
        preview: 1,
        title: {
            details: {
                place: 'Hostomice',
                work: 'Přestavba koupelny a kuchyně'
            },
            date: 'rok 2019',
        },
        images_source: {
            name: 'koupelna_kuchyne',
            extension: 'jpg',
            min: 1,
            max: 4,
            exclude: [ 1 ]
        }
    }, {
        key: 3,
        core_url: 'sobedruhy/kancelar_koupelna/',
        preview: 1,
        title: {
            details: {
                place: 'Sobědruhy',
                work: 'Kancelářské prostory a koupelna'
            },
            date: 'rok 2019',
        },
        images_source: {
            name: 'kancelar_koupelna',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 1 ]
        }
    }, {
        key: 2,
        core_url: 'kostomlaty/rekonstrukce_bytu/',
        preview: 7,
        title: {
            details: {
                place: 'Kostomlaty',
                work: 'Rekonstrukce bytu'
            },
            date: 'rok 2019',
        },
        images_source: {
            name: 'rekonstrukce_bytu',
            extension: 'jpg',
            min: 1,
            max: 12,
            exclude: [ 7 ]
        }
    }, {
        key: 1,
        core_url: 'kostomlaty/rekonstrukce_koupelny/',
        preview: 4,
        title: {
            details: {
                place: 'Kostomlaty',
                work: 'Rekonstrukce koupelen'
            },
            date: 'rok 2019',
        },
        images_source: {
            name: 'rekonstrukce_koupelny',
            extension: 'jpg',
            min: 0,
            max: 7,
            exclude: [ 4 ]
        }
    }, {
        key: 0,
        core_url: 'lom/zaklady_domu/',
        preview: 1,
        title: {
            details: {
                place: 'Lom',
                work: 'Stavba základů rodinného domu'
            },
            date: 'rok 2018',
        },
        images_source: {
            name: 'dum',
            extension: 'jpg',
            min: 1,
            max: 7,
            exclude: [ 1 ]
        }
    },
];


function firstLetterToLower(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function firstLetterToUpper(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


let galleryItemsNew = [];

galleryItems.map(function(item) {
    item.images = [];

    for(let i = item.images_source.min; i <= item.images_source.max; i++) {
        if((item.images_source.exclude).includes(i)) {
            continue;
        }

        let image_path = item.images_source.name === '' ?
            (i + '.' + item.images_source.extension)
            : (item.images_source.name + '_' + i + '.' + item.images_source.extension);

        item.images.push(image_path);
    }

    item.preview = item.images_source.name === '' ?
        (item.preview + '.' + item.images_source.extension)
        : (item.images_source.name + '_' + item.preview + '.' + item.images_source.extension);

    item.title.preview = firstLetterToUpper(item.title.details.place) + ' - ' + firstLetterToLower(item.title.details.work);
    item.title.album = firstLetterToUpper(item.title.details.work) + ', ' + firstLetterToUpper(item.title.details.place);

    galleryItemsNew[galleryItems.length - item.key] = item;
});

let itemsPerPage = 6;

// empty item with index 0 sneaks in! that's why -1 is there
let galleryItemsProps = {
    items: Number(galleryItemsNew.length) - 1 ,
    pages: Number(Math.ceil((galleryItemsNew.length - 1) / itemsPerPage)),
    itemsPerPage: itemsPerPage
};


export { galleryItemsNew as GalleryItems, galleryItemsProps as GalleryItemsProps };