import React, { Component } from 'react';
import myConfig from '../../config/myconfig';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

class GalleryAlbum extends Component {

    render() {
        let galleryItemsFinal = [];
        this.props.item.images.map((item, index) => {
            let item_name = item;
            /*if(item.includes('.jpg')) {
                item_name = item_name.split('.jpg');
                item_name = item_name[0] + '-min.jpg';
            }*/

            item_name = '/assets/gallery/' + this.props.item.core_url + item_name;
            galleryItemsFinal.push(item_name);
        });

        let preview_url = '/assets/gallery/' + this.props.item.core_url + this.props.item.preview;
        /*if(preview_url.includes('.jpg')) {
            preview_url = preview_url.split('.jpg');
            preview_url = preview_url[0] + '-min.jpg';
        }*/

        return (
            <SimpleReactLightbox>
            <SRLWrapper options={myConfig.lightbox} id={this.props.item.key}>
                <div className={'album'}>
                    <div className={'preview'}>
                        <div className={'date'}>{this.props.item.title.date}</div>
                        <img src={preview_url} width={'100%'} height={'100%'} alt={this.props.item.title.album} title={this.props.item.title.album}
                             data-attribute={'SRL_' + this.props.item.key} />
                        {galleryItemsFinal.map((item, index) => (
                            <img className={'hidden'} src={item} alt={this.props.item.title.album} title={this.props.item.title.album}
                                 key={this.props.item.key + "-" + item} data-attribute={'SRL_' + this.props.item.key} />
                        ))}
                    </div>
                    <h2>{this.props.item.title.preview}</h2>
                </div>
            </SRLWrapper>
            </SimpleReactLightbox>
        );
    }
}

export default GalleryAlbum;